<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img class="clo" @click="closeDialogs" src="@/assets/btn_close.png" alt />
      <div class="card_img d-flex flex-column mr-lg-16">
         <div class="title mt-3 mb-2 " v-if="isEdit" style="font-size: 23px !important">
           {{$t('edit_folder')}}
        </div>
        <div class="title mt-3 mb-2 " v-else style="font-size: 23px !important">
           {{$t('add_folder')}}
        </div>
         <div class="des  mb-2 " >
           {{$t('choose_folder_des')}} 
        </div>
        <img
          v-if="uploadFileUrl"
          class="clo"
          width="31px"
          height="31px"
          @click.stop="closeDialog"
          src="@/assets/btn_close.png"
          alt=""
        />

        <div
          class="d-flex flex-column justify-center align-center creatimg"
          style="
            width: 451px;
            height: 450px;
            border: dashed 2px #e3e3e3;
            position: relative;
            overflow: hidden;
          "
        >
          <!-- .jpg,.png,.gif,.mp4 -->
          <FIleInput
            accept=".jpg,.jpeg,.png,.gif"
            @change="change"
            v-if="!uploadFileUrl"
            :key="fileInputKey"
          >
            <div
              class="uploadbox drop_area"
              :class="{ dropwait: isDrop }"
              @drop="enentDrop"
              @dragleave="dragleave"
              @dragenter="dragleave"
              @dragover="dragleave"
            >
              <div class="drop_txt"></div>
              <template>
                <v-btn class="uc " width="161px" height="40px" rounded
                  >{{ $t("createChooseFile") }}
                </v-btn>
                 <div class="mt-5" style="font-size:14px">{{ $t("createFolderLimit") }}</div>
                <div style="font-size:14px">{{ $t("createLimitNotVideo") }}</div> 
              </template>
              <div class="progress_area" v-if="isDrop">
                <span :style="{ width: progressValue + '%' }"></span>
              </div>
            </div>
          </FIleInput>
          <video
            v-else-if="fileType == 'video/mp4'"
            :src="localFileUrl || uploadFileUrl"
            width="100%"
            height="100%"
            contain
            loop
            controls
            controlslist="nodownload"
          ></video>
          <v-img
            v-else
            :src="localFileUrl || uploadFileUrl"
            width="100%"
            height="100%"
            contain
          ></v-img>
        </div>

        <div>
          <div class="title mt-3 mb-2"> {{ $t("folder_name") }} <span class="star">*</span></div>
          <div class="box">
            <v-text-field
              ref="nameValue"
              v-model="nameValue"
              :rules="nameRules"
              label=""
              outlined
              :key="refresh_key"
            >
            </v-text-field>
          </div>
        </div>
        <div>
          <div class="title mt-3 mb-2">
            {{ $t("popupAccountAppealDescription") }}<span class="star">*</span>
          </div>
          <div class="box">
            <v-textarea
              ref="descriptionValue"
              :rules="descRules"
              outlined
              name="input-7-4"
              label=""
              value=""
              v-model="descriptionValue"
              :key="refresh_key"
            ></v-textarea>
          </div>
        </div>
       <div>
          <div class="title mt-2 mb-2">{{ $t("shortUrl") }}<span class="star">*</span></div>
          <div class="desc">{{ $t("url_describe") }}</div>
          <div class="url mb-2">https://irisnet.upticknft.com/</div>
          <div class="box">
            <v-text-field ref="shortUrlValue" v-model="shortUrlValue" :rules="urlRules" label="" outlined
              :key="refresh_key" maxlength="50" :disabled="isEdit"></v-text-field>
          </div>
        </div>
           <div class="copyright box d-flex flex-row ">
          <div class="checkbox mt-n5">
            <v-checkbox v-model="checkbox" label=""></v-checkbox>
          </div>
          <div class="content">
            {{ $t("createPleaseEnsure") }}
            <p>
              {{ $t("createAgreed")
              }}<span style="cursor: pointer" @click="toTerms"
                >《{{ $t("createCopyright") }}》</span
              >
            </p>
          </div>
        </div>
        <div class="footer box">
      <v-btn class="sub" height="51px" disabled v-if="subDisabled">
        {{ $t("popupTransferCommit") }}
      </v-btn>
      <button class=" sub" height="51px" @click="subCommit" v-else>
        {{ $t("popupTransferCommit") }}
      </button>
      <span class="price mt-4"
        >{{ $t("balanceCount") }}: {{ transferValue }} IRIS</span
      >

    </div>

      </div>
      
    </v-card>
     <div class="mask" v-if="showMask">
      <img class="img" src="@/assets/loading.gif" alt="" />
    </div>
  <PromptBox ref="promptBox" @commit="promptBoxCommit"></PromptBox>
    <uComponents  ref="ucom"></uComponents>  
  </div>
</template>
<script>
import { getFileSrc } from "@/utils/file";
import FIleInput from "@/components/FIleInput.vue";
import api from "@/api";
import { toIpfsLink, toBaseUri,toS3Link } from "../../utils/helper";
import PromptBox from "@/components/PromptBox.vue";
import { quiryTx,issueDenom} from "../../keplr/iris/wallet";
import { getMyBalance } from "../../keplr/iris/wallet";

const Web3 = require('web3');
 
const UPLOAD_KEY = "UPLOAD";
let fileUrl = "";
let imageUrl = "";
export default {
  components: { FIleInput,PromptBox },
  props: {
   
    NFTInfo:{
      type:Object
    },
     isCreate:{
      type:Boolean
    }
   
  },
  data: function () {
    return{
        isPay: false,
    refresh_key: 0,
    open: false,
    transferValue:'',
    feeValue:'',
    isEdit:false,
    subDisabled: false,
    nameValue:'',
    descriptionValue: "",
    sum: 1,
     uploadFileHash: {
      imgUrl: "",
      name: "　",
      did: "",
      ownerName: "",
      ownerPhoto: "",
    },
    fileInputKey: 0,
    uploadFileUrl: "",
    uploadImageUrl: "",
    showUploadImage: false,
    localFileUrl: "",
    fileType: "",
    showMask: false,
    commit_msg: "",
    methodType: 11,
    isDrop: false,
    isDropImg: false,
    progressValue: 0,
      checkbox: false,
    CategoryList: [
      {
        texts: this.$t('otherpageTabsPageArtwork') ,
        value: 1,
      },
      {
        texts: this.$t('market_Photograph'),
        value: 2,
      },
      {
        texts: this.$t('market_PFP'),
        value: 3,
      },
      {
        texts: this.$t('amCreateCollectibles'),
        value: 4,
      },
    ],
    nameRules: [
      (v) => !!v || "Please upload an name for your item",
      (v) => !(/[!$%^*(){}:"<>?！￥]/.test(v)) || "Cannot contain special symbols",
      (v) => (v && v.length <= 80) || "Name must be less than 80 characters",
    ],
    urlRules: [
      (v) => !!v || "URL format error",

      (v) => (/^[0-9a-zA-Z_]{1,}$/.test(v)) || "URL format error",

    ],
     descRules: [(v) => !!v || "Please upload an description for your item"],
    rules: {
      EditionValue: (value) => {
        // const pattern = /^([1-9][0-9]{0,2}|100)$/;
        const pattern = /^[0-9]$|^[0-4][0-9]$|^50$/
        return pattern.test(value) || "Invalid amount for sale";
      },
    },
      categoryValue: "",
      shortUrlValue:'',
      contractaddress:'',
      transactionHash:'',
      attribute:3,
      feeValueFormat:0.1
      
      
    }
   
   
  },
  watch: {
    
    nameValue: {
      handler: function (newValue) {
        this.uploadFileHash.name = newValue;
        this.submitStatus();
      },
      immediate: true,
    },
    uploadFileUrl: {
      handler: function () {
        this.submitStatus();
      },
      immediate: true,
    },
    descriptionValue: {
      handler: function () {
        this.submitStatus();
      },
      immediate: true,
    },
    shortUrlValue: {
      handler: function () {
        this.submitStatus();
      },
      immediate: true,
    },
   
    checkbox: {
      handler() {
        this.submitStatus();
      },
      immediate: true,
    },
    
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  async mounted(){
  debugger
                await getMyBalance();
	 let mount = localStorage.getItem("key_balance");
      this.transferValue = mount;
  console.log("wxl -- wqwq", this.transferValue )
      if(this.NFTInfo && this.NFTInfo.linkUrl != '' && !this.isCreate){
        debugger
          this.isEdit = true

      if (this.NFTInfo.category == '2') {
        this.categoryValue = 1;
      } else if (this.NFTInfo.category == '4') {
        this.categoryValue = 2;
      } else if (this.NFTInfo.category == '1') {
        this.categoryValue = 3;
      } else if (this.NFTInfo.category == '3') {
        this.categoryValue = 4;
 
      }


          // this.categoryValue = Number(this.NFTInfo.category) ;
           this.uploadFileUrl = this.getImageIpfs(this.NFTInfo.collectionImgUrl);
          this.nameValue = this.NFTInfo.name;
          this.shortUrlValue = this.NFTInfo.linkUrl;
          this.uploadFileHash.imgUrl = this.getImageIpfs(this.NFTInfo.collectionImgUrl)
      //    this.folderId = nftInfo.id
          imageUrl = this.NFTInfo.collectionImgUrl
         if(this.NFTInfo.description){
                this.descriptionValue = this.NFTInfo.description.replace(
        /<br\s*\/?>/g, "\n"
      );
      }
      }
 

  },
  
  methods: {
   
      verify() {
      // let mountVer = this.$refs.amountValue.validate(true);
      let nameVer = this.$refs.nameValue.validate(true);
      let emailVer = this.$refs.descriptionValue.validate(true);
      let shortUrlVer = this.$refs.shortUrlValue.validate(true);

      // !mountVer ? this.$refs.amountValue.focus() : "";
      !nameVer ? this.$refs.nameValue.focus() : "";
      !emailVer ? this.$refs.descriptionValue.focus() : "";
      !shortUrlVer ? this.$refs.shortUrlValue.focus() : "";

      return nameVer && emailVer && shortUrlVer;
    },
     submitStatus() {
      this.subDisabled = !(
        this.nameValue &&
        this.uploadFileUrl &&
        this.descriptionValue &&
        this.checkbox &&
        this.shortUrlValue&&
        parseFloat(this.feeValueFormat) < parseFloat(this.transferValue)
 
      );
      console.log("wxl --- 111", this.nameValue, this.uploadFileUrl, this.descriptionValue,  this.checkbox, parseFloat(this.feeValueFormat), parseFloat(this.transferValue))
    },
     async subCommit() {
    

      if (!this.verify()) {
        return;
      }
    
debugger
      // 检查linkurl
      let checkUrlParams={
         linkUrl:this.shortUrlValue
      }
         if(this.NFTInfo && this.NFTInfo.linkUrl != ''){
           checkUrlParams.contractCollectionId = this.NFTInfo.contractCollectionId
         }

       let checkUrl_res = await api.home.checkLinkUrl(checkUrlParams);
       if(!checkUrl_res.success){
          this.$toast("error",checkUrl_res.msg);
           this.showMask = false;
          this.isPay = false
          return;
       }
      

      // get attribute  3-artwork 4-collectibles 5-photography 7-video
      if (this.categoryValue === 1) {
        this.attribute = 2;
      } else if (this.categoryValue === 2) {
        this.attribute = 4;
      } else if (this.categoryValue === 3) {
        this.attribute = 1;
      } else if (this.categoryValue === 4) {
        this.attribute = 3;

      }
      this.showMask = true;

      try {
        

         this.isPay = true

          let name = this.nameValue;
        let sender = this.$store.state.did;
        let res = await api.home.getFee();
        let fee =0;
        let feeUrl = Number(res.data.MINT_ASSET_FEE);
        let adminAddress = res.data.IrisAddress;
        let account = Number(this.amountValue);
     
          let resfolder;
          if(this.NFTInfo && this.NFTInfo.linkUrl != '' && !this.isCreate)
          {
               let param = {   
          contractCollectionId:this.NFTInfo.contractCollectionId,
          owner: sender,
          description:encodeURIComponent(this.descriptionValue) ,
		      collectionFileUrl: fileUrl,
          collectionImgUrl: imageUrl,
          linkUrl:this.shortUrlValue,
          name: this.nameValue,
          denomType: 2,
          attribute: this.attribute,
        };
            resfolder = await api.home.FolderUpdate(param);
          }else{
           let param = {   
          owner: sender,
          description:encodeURIComponent(this.descriptionValue) ,
		      collectionFileUrl: fileUrl,
          collectionImgUrl: imageUrl,
          linkUrl:this.shortUrlValue,
          name: this.nameValue,
          denomType: 2,
           attribute: this.attribute,
    
        };
            resfolder = await api.home.createFolder(param);
          }
        
           this.showMask = false;
            this.$emit("getOpen", false);
           console.log(resfolder)
           if(resfolder.success == true){
            this.$toast("success", this.$t("amCreateSuccessTitle")).then(() => {
          
        });
           }
    
    
 
      } catch (e) {
       this.$toast("error",this.$t("requireFailed"))
      this.showMask = false;
       this.isPay = false
      }
    },

    closeDialogs() {
      console.log("wxl --- wewewew")
      this.$emit("getOpen", this.open);
    },
    
    async change(file) {
       let ft = file.name.substr(file.name.lastIndexOf(".")+1);
        if("JPG,PNG,GIF,MP4,JPEG".indexOf(ft.toUpperCase()) == -1) {
            this.$toast("error", this.$t("file_type_not_support"));
            this.fileInputKey++;
            return;
        }

      this.isDrop = true;
      this.fileInputKey++;
      console.log(223,file.name);
      
      console.log("xxl file 1111 :");
      this.fileType = file.type;
      console.log(file.type); //video/mp4
      if (file.type == "video/mp4") {
        if(file.size > 20971520 
		&& this.$store.state.did != "iaa1lv27ac3ztjz6u90y0a8ayr8ql20fzda399cu6j"
		&& this.$store.state.did != "iaa10alustkamdsqknq22pxaqs22ahy8vhht6gte76"
		&& this.$store.state.did != "iaa1dqfet703fj838m9u3yq68h2rczt8fr3e57agal"
		&& this.$store.state.did != "iaa1wzu2k2tsghgj32h5a6allhf69jy2jg2quhlnvd"
		&& this.$store.state.did != "iaa15k328z4v65qd7tp82fcvrymfmfn6lnkq5ep30c") {
            this.isDrop = false;
            this.$toast("error", this.$t("video_not_support"));
            return;
        }
        let res = await api.video.uploadVideo(file, this.onUploadProgress);
        if(res.data.data == null) {
            this.isDrop = false;
            this.$toast("error", res.data.msg);
            return;
        }
        fileUrl = res.data.data;
        console.log(fileUrl);
        this.uploadFileUrl = await getFileSrc(UPLOAD_KEY, res.data.data, true);
		this.showUploadImage = true;
        this.categoryValue = 4;
      } else {
        if(file.size > 10242880) {
            this.isDrop = false;
            this.$toast("error", this.$t("image_not_support"));
            return;
        }
        let res = await api.image.uploadImage(file, this.onUploadProgress);
        if(res.data.data == null) {
            this.isDrop = false;
            this.$toast("error", res.data.msg);
            return;
        }
        imageUrl = res.data.data;
		this.uploadFileHash.imgUrl=imageUrl
        console.log(imageUrl);
        this.uploadFileUrl = await getFileSrc(UPLOAD_KEY, res.data.data);
		this.showUploadImage = false;
		if( this.categoryValue == 4){
			this.categoryValue=1
		}
      }
      this.isDrop = false;
      this.progressValue = 0;
    },
    async imgChange(file) {
      // JPG,PNG,GIF
      let ft = file.name.substr(file.name.lastIndexOf(".") + 1);
      if ("JPG,JPEG,PNG,GIF".indexOf(ft.toUpperCase()) == -1) {
        this.$toast("error", this.$t("file_type_not_support"));
        this.fileInputKey++;
        return;
      }

      this.isDropImg = true;
       let path = await api.image.uploadImage(file,this.onUploadProgress);
      //let { path } = await this.ipfsAddFile(file);
      this.fileInputKey++;
      if (!path) {
        this.isDropImg = false;
        this.isShowFalse = true;
        // this.falseTitle = res.data.msg;
        return;
      }
      imageUrl = path.data.data;
      this.uploadFileHash.imgUrl = imageUrl;
      this.uploadImageUrl = await getFileSrc(UPLOAD_KEY, path.data.data);
      this.showUploadImage = false;
      this.isDropImg = false;
    },
    closeDialog() {
      this.uploadFileUrl = "";
      this.imgUrl = "";
      this.showUploadImage = false;
      this.uploadImageUrl = "";
      this.uploadFileHash.imgUrl = "00";
      if (this.categoryValue == 4) this.categoryValue = 1;
    },
    closeImgDialog() {
      this.uploadImageUrl = "";
      this.imgUrl = "";
      this.uploadFileHash.imgUrl = "00";
      if (this.fileType == "video/mp4" && this.uploadFileUrl) {
        this.showUploadImage = true;
        this.categoryValue = 4;
      } else {
        this.showUploadImage = false;
      }
    },
    promptBoxCommit(type) {
      if (type == 1) {
        this.$router.push({ name: "PersonalPage" });
        sessionStorage.setItem("PersonalPage_Tabs", 1);
      }
    },
    toTerms() {
      if (this.$vuetify.lang.current == "en") {
        let routeUrl = this.$router.resolve({
          path: "/term",
          query: { id: 96 },
        });
        window.open(routeUrl.href, "_blank");
      } else {
        let routeUrl = this.$router.resolve({
          path: "/term_zh",
          query: { id: 96 },
        });
        window.open(routeUrl.href, "_blank");
      }
    },

    enentDrop(e) {
      e.stopPropagation();
      e.preventDefault(); //必填字段
      let file = e.dataTransfer.files[0];
      this.change(file);
    },
    enentDropImg(e) {
      e.stopPropagation();
      e.preventDefault(); //必填字段
      let file = e.dataTransfer.files[0];
      this.imgChange(file);
    },
    dragleave(e) {
      e.stopPropagation();
      e.preventDefault();
    },
    onUploadProgress(e) {
      this.progressValue = (e.loaded / e.total) * 100;
    },
  },
};
</script>

<style lang="scss" scoped>
.card ::v-deep .v-messages__message {
  color: red;
}
.mask {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card {
  width: 410px;
  min-height: 960px;
  

  .card_img {
      position: relative;
        margin-left: 50px !important;
      .title{
        font-family: Helvetica;
         font-weight: bold;
        font-size: 15px !important;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
        display: flex;
        align-items: center;
        .star {
  color: red;
  font-size: 18px;
  font-family: "宋体";
  margin-left: 10px;
}
      }
      .des{
        height: 30px;
        font-family: Helvetica;
        font-size: 13px;
        font-weight: normal;
        letter-spacing: 0px;
        color: #766983;
      }
      .creatimg {
        width: 300px !important;
        height: 300px !important;
      
        margin-top: 20px !important;
        .uploadbox {
          text-align: center;
          margin: 100px auto 0;
        }
      }
      

      .clo {
        visibility: hidden;
        position: absolute;
        right: 0px;
        top: 115px;
        background-color: #fff;
        border-radius: 50%;
        cursor: pointer;
        z-index: 9999;
      }

      &:hover .clo {
        visibility: visible;
      }

      .upload {
        text-align: center;
        vertical-align: middle;
        height: 450px;
        background-image: linear-gradient(#ffffff, #ffffff),
          linear-gradient(#f8f6fd, #f8f6fd);
        background-blend-mode: normal, normal;
        border-radius: 5px;
        border: solid 2px #e3e3e3;
      }

      .copyright {
        .content {
          p {
            margin-top: 20px;
            font-family: Helvetica;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;

            span {
              color: #6f58d9;
              text-decoration: none;
            }
          }
        }
      }
        .footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 50px;
     
   

    .price {
      display: block;
      font-family: Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      color: #766983;
    }

    .sub {
      margin: 14px auto;
      width: 300px;
      height: 50px;
     	background-color: #7800f4;
      border-radius: 25px;
      opacity: 0.9;
      font-weight: bold;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #ffffff;
    }
     .sub-dis {
  position: relative;
  pointer-events: none;
  background-image: linear-gradient(#766983,
      #766983),
    linear-gradient(#270645,
      #270645) !important;
  background-blend-mode: normal,
    normal;
  border-radius: 25px;
  opacity: 0.9;
}

.sub-dis::after {
  content: "";
  background-image: url(../../assets/loading.gif);
  background-size: 100%;
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-top: 5px;
}
  }
    }
  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }
   .progress_area {
    width: 270px;
    height: 10px;
    background-color: #bbb;
    border-radius: 30px;
     margin-top: 30px;
     margin-left: 10px;
    overflow: hidden;

    span {
      display: block;
      background-color: #766983;
      height: 100%;
      width: 0%;
    }
  }

  .top {
    margin: 0 52px;
    display: flex;
    flex-direction: column;
    h3 {
      margin: 25px 0 15px;
      font-weight: bold!important;
      font-size: 25px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }
    span{
        font-family: Helvetica;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
    }
  }
  .link {
    margin: 30px 48px 45px;
    ul {
      list-style: none;
      padding: 0;
      li {
        text-align: center;
        cursor: pointer;
        width: 290px;
        height: 220px;
        background-image: linear-gradient(#ffffff, #ffffff),
          linear-gradient(#f8f6fd, #f8f6fd);
        background-blend-mode: normal, normal;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
        border-radius: 5px;
        display: block;
        margin-bottom: 9px;
        img {
          display: block;
          margin: 20px auto 15px;
        }
        .infotit{
            font-family: Helvetica;
            font-size: 17px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
        }
        .infotype{
            margin-top: 8px;
            font-family: Helvetica;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #766983;
        }
        .infotitauc {
          height: 22px;
          font-weight: bold;;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          line-height: 22px;
          color: #270645;
          margin: 0 auto 7px;
        }
        .infos {
            margin-top: 8px;
            font-family: Helvetica;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
        }
      }
      .onlyread {
        .infotitauc,
        .infos {
          color: #ccc;
        }
      }
      li:hover {
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.36);
      }
    }
  }
  &.mobile {
     .card_img{
      margin-left: 20px !important;
      margin-right: 20px !important;
    }
    .top {
      h3 {
        font-size: 20px;
      }
      p {
        height: auto;
      }
    }
    .link {
      ul {
        li {
          img {
            margin: 35px auto;
          }
        }
      }
    }
  }
}
</style>
